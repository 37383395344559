import React from 'react';
import { connect } from 'react-redux';
import { betActions, userActions } from '../../_actions';
import Loader from '../../components/Loader/Loader';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import Sidebar from "../../components/Sidebar/Sidebar";


class TransictionHistory extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      declareMatchModal: false,
      setFmatchId: false,
      declareMatchDetails: {},
      currentCount: "Sport",
      noOfRecords: 20,
      index: 0,
      betType: 1,
      size: 10,
      optionRadioName: "MATCHED",
      endDate: "",
      startDate: "",
      sportsId: "",
      limit: "",
      offset: 0,
      pageNo: 1,
      size: 10,
    }
  }

  componentDidMount() {
    let data = {
      // "fromDate": moment(this.state.startDate).startOf('day').unix(),
      // "toDate": moment(this.state.endDate).endOf('day').unix(),
      "keyWord": "",
      "sortBy": "",
      "sortOrder": "",
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }

    this.props.dispatch(betActions.transactionHistoryForWeb(data));
    // this.props.dispatch(userActions.getBetsList(data));

    let gameReqData =
    {
      "keyWord": "",
      "pageNo": this.state.pageNo,
      "sortBy": "slug",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
      "size": this.state.size
    }

    this.props.dispatch(userActions.getGamesList(gameReqData));
  }


  getBatHistory = () => {
    let data = {
      "fromDate": this.state.startDate,
      "toDate": this.state.endDate,
      "keyWord": this.state.sportsId,
      "sortBy": "",
      "sortOrder": "",
      "pageNo": this.state.pageNo,
      "size": this.state.size,
      
    }

    this.props.dispatch(betActions.transactionHistoryForWeb(data));
  }

  inputChangeStartDate = (e) => {
    this.setState({ startDate: e.target.value });
  }

  inputChangeEndDate = (e) => {
    this.setState({ endDate: e.target.value });
  }

  handleChangeSelect = (event) => {
    this.setState({ sportsId: event.target.value });
  };


  inputChangeCurrentCount = (event) => {
    this.setState({ currentCount: event });
  }

  handlePageClick = (data) => {
    console.log("data  ", data);
    this.setState({ index: data.selected });

    let offset = Math.ceil(data.selected * this.state.size);

    this.setState({ offset: offset });

    this.setState({ pageNo: data.selected + 1 }, () => {
      let pagination = {
        "fromDate": this.state.startDate,
        "toDate": this.state.endDate,
        "keyWord": "",
        "sortBy": "",
        "sortOrder": "",
        "pageNo": this.state.pageNo,
        "size": this.state.size,
      }
      this.props.dispatch(betActions.transactionHistoryForWeb(pagination));
    })

  }

  render() {

    let { bet, users } = this.props;
    let {  loading,betLoading, totalBet,tnxTotal,tnxList } = bet;
    let { totalGame, gameList } = users;
     // Get today's date in YYYY-MM-DD format
  const today = new Date().toISOString().split('T')[0];

    return (

      <>
        <div>
        <Loader loading={loading} />
        </div>
        <div className='bg-gray-200 w-full min-h-screen flex py-10'>
        {/* <div className='bg-white w-full min-h-screen flex py-10' style={{ backgroundImage: "url(/images/mw-ban.png)" }}> */}
          <div className='lg:w-[90%] w-full  space-y-4 mx-auto'>
          <div className=' text-xl font-bold border-b-2 border mb-2'>Transaction History</div>
            <div className='  border grid grid-cols-1 lg:grid-cols-7 divide-x  lg:divide-y-0'>
              <div className='col-span-1 lg:col-span-2 w-full p-4 flex flex-col justify-center items-center'>
                <label className=''>From Date</label>
                <input max={today}   type="date" className=" w-full  border border-black px-3 py-1.5 text-[0.8125rem] bg-transparent rounded focus:outline-none"
                  name="Date"
                  dateFormat="yyyy-mm-dd"
                  id="datepicker" required
                  value={this.state.startDate} onChange={this.inputChangeStartDate} />
              </div>
              <div className='col-span-1 lg:col-span-2 w-full p-4 flex flex-col justify-center items-center'>
                <label className=''>To Date</label>
                <input
                max={today}
                type="date" className=" w-full  border border-black px-3 py-1.5 text-[0.8125rem] bg-transparent rounded focus:outline-none"
                  name="Date"
                  dateFormat="yyyy-mm-dd"
                  id="datepicker" required
                  value={this.state.endDate} onChange={this.inputChangeEndDate}
                />
              </div>
          
              <div className='col-span-1 flex flex-col justify-end items-end p-4'>
              <label className=''> </label>
                <button onClick={() => this.getBatHistory()} className='py-1.5 px-4 bg-[#17273b] text-white text-[0.8125rem] rounded'>Submit</button>
              </div>
            </div>
            <div className="overflow-hidden ">
              <div className="max-w-full overflow-auto ">
                <div className="inline-block min-w-full ">
                  <div className="overflow-hidden w-full ">
                  <div className="overflow-hidden w-full ">

<div className=' w-full  flex flex-col  gap-5 border-2 p-2'>


  {tnxList && tnxList.length > 0 ? tnxList.map((element, index) => {

    return (
      <div className=' flex  w-full flex-col  text-xs border-gray-500 rounded-r-md border p-4'>
        <div className=' flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1  uppercase'><div>S.No.</div>  {this.state.offset + index + 1}</div>
        <div className=' flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1  uppercase'><div>Type</div>    {element && element.type && element.type? element.type: "-"}</div>
        {/* <div className=' flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1  uppercase'><div>Game</div>{element && element.gameId && element.gameId.name ? element.gameId.name : "-"}</div> */}
        {/* <div className=' flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1  uppercase'><div>NUMBER</div>{element && element.betNumber ? element.betNumber : "-"}</div> */}
        <div className=' flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1  uppercase'><div>Amount</div> {element && element.amount ? element.amount : "-"}</div>
        {/* <div className=' flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1  uppercase'><div>BID TYPE</div>{element && element.isOpen ? "Open" : "Close"}</div> */}
        <div className=' flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1  uppercase'><div>Date</div>{moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("DD-MM-YYYY hh:mm A")}</div>
        <div className=' flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1  uppercase'><div>Status</div>  {element && element.betStatus == 0 && <div className=' text-yellow-500'>Pending</div>}
        {element && element.status == 0 && <div className=' text-yellow-500'>Pending</div>}
                                    {element && element.status == 1 && <div className=' text-green-800'>Success</div>}
                                    {element && element.status == 2 && <div className=' text-red-700'>Rejected</div>}
          </div>
      </div>
    )
  })
:
<span>No data Found</span>
}
</div>
{

  <nav className="relative z-0 flex mb-10 justify-end  w-76">
    {
      tnxTotal && tnxTotal> 10 ?
        <ReactPaginate
          previousLabel={'Prev'}
          nextLabel={'Next'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={tnxTotal / 10}
          marginPagesDisplayed={5}
          pageRangeDisplayed={5}
          onPageChange={this.handlePageClick}
          containerClassName={'pagination'}
          pageClassName={'page-cls'}
          activeClassName={'active'}
        />
        : null}
  </nav>
}

</div>




                    {/* <div class="relative overflow-x-auto rounded-lg">
                      <table class="w-full text-sm text-left rtl:text-right text-gray-500 rounded-md ">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50  ">
                          <tr>
                            <th scope="col" class="px-6 py-3">
                              S.No.
                            </th>
                          
                            <th scope="col" class="px-6 py-3">
                              Type
                            </th>
                         
                            <th scope="col" class="px-6 py-3">
                              Coins
                            </th>
                       
                            <th scope="col" class="px-6 py-3">
                              Date
                            </th>
                            <th scope="col" class="px-6 py-3">
                              Status
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {tnxList && tnxList.length > 0 ? (
                            tnxList.map((element, index) => {
                              console.log(element)
                              return (
                                <tr class="bg-white border-b  ">
                                  <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                                    {this.state.offset + index + 1}
                                  </th>
                               
                                  <td class="px-6 py-4  whitespace-nowrap">
                                    {element && element.type && element.type? element.type: "-"}
                                  </td>
                            
                                  <td class="px-6 py-4  whitespace-nowrap ">
                                    {element && element.amount ? element.amount : "-"}
                                  </td>
                                
                                  <td class="px-6 py-4 whitespace-nowrap">
                                    {moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("DD-MM-YYYY hh:mm A")}
                                  </td>
                                  <td class="px-6 py-4">
                                    {element && element.status == 0 && <div className=' text-yellow-500'>Pending</div>}
                                    {element && element.status == 1 && <div className=' text-green-800'>Success</div>}
                                    {element && element.status == 2 && <div className=' text-red-700'>Rejected</div>}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td>

                                No Data Found
                              </td>

                            </tr>
                          )}




                        </tbody>
                      </table>
                      {

                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            tnxTotal && tnxTotal > 10 ?
                              <ReactPaginate
                                previousLabel={'Prev'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={tnxTotal / 10}
                                marginPagesDisplayed={5}
                                pageRangeDisplayed={5}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav>
                      }
                    </div> */}










                  </div>
                </div>






              </div>
            </div>
          </div>
        </div>
      </>

    );
  }
}

function mapStateToProps(state) {
  const { users, bet } = state;
  return {
    users,
    bet
  };
}

export default connect(mapStateToProps)(TransictionHistory);

