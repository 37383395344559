import React from "react";
import { connect } from "react-redux";
import { DomainName } from "../../_config/index";
import { alertActions, userActions } from "../../_actions";
import moment from "moment";
import RulesNotification from "../../components/RulesNotification/RulesNotification";
import { FcAlarmClock } from "react-icons/fc";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaPause } from "react-icons/fa";
import BetCloseAlert from "../../components/CloseBetAlert/CloseBetAlert";
import schedule from "./schedule.png";
import { FaPlay } from "react-icons/fa";
import { IoChevronBackCircleSharp } from "react-icons/io5";
import Loader from "../../components/Loader/Loader";

class Dashboard2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      gameItem: {},
      activeTab: 1,
      notification: false,
      editStake: false,
      timeDifference: null,
      isPastClosingTime: false,
    };
  }

  componentDidMount() {
    const userId = localStorage.getItem("spuser");
    const parentiddemo = userId ? JSON.parse(userId) : null;
    const parentId = parentiddemo?.data?.parentId;

    const data = {
      keyWord: "",
      pageNo: 1,
      sortBy: "slug",
      sortOrder: "asc",
      fromDate: "",
      toDate: "",
      size: 100,
    };

    this.props.dispatch(userActions.getAllSlider());
    this.props.dispatch(userActions.getAllAppSetting());

    this.intervalId = setInterval(() => {
      this.props.dispatch(userActions.getGamesList(data));
      this.props.dispatch(userActions.getProfileforAdmin({ userId: parentId }));
    }, 2000);
  }

  componentWillUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  navigateGameDetailsPage = (data) => {
    const closingTimeInMillis = data.closingTime;
    const currentTimeInMillis = new Date().getTime();

    if (currentTimeInMillis < closingTimeInMillis) {
      // this.props.history.push(`/app/matkaDetails/${data.name}/${data._id}`)

      this.props.history.push(`/app/matkaDetails/${data._id}/pocket`);
    } else {
      // this.setState({ isPastClosingTime: true });
      // this.props.history.push(`/app/matkaDetails/${data.name}/${data._id}`)
      this.props.history.push(`/app/matkaDetails/${data._id}/pocket`);
    }
  };

  handleCloseAlert = () => {
    this.setState({ isPastClosingTime: false });
  };

  handleCloseNotification = () => {
    // this.setState({ notification: false });
    // localStorage.setItem('hasSeenPopup', true);
  };

  render() {
    const currentTimeApis = moment().utcOffset("+05:30");
    const { activeTab, notification, isPastClosingTime } = this.state;
    let { users } = this.props;
    let {
      totalGame,
      loading,
      gameList,
      slideData,
      appSettingData,
      parentInfo,
    } = users;
    let {
      currentTime,
      firstRoundopenTime,
      secoundRoundopenTime,
      firstRoundclosingTime,
      secoundRoundclosingTime,
    } = parentInfo ? parentInfo : {};
    // first round related -------------------
    console.log(firstRoundopenTime);

    // const currentTimeApis = moment.unix(currentTime).format("hh:mm A");
    let openTimingFirstround = moment()
      .hours(parseInt(firstRoundopenTime && firstRoundopenTime / 3600))
      .minutes(parseInt((firstRoundopenTime && firstRoundopenTime % 3600) / 60))
      .seconds(parseInt(firstRoundopenTime && firstRoundopenTime % 60))
      .milliseconds(0);
    const OpenBetTimeFirstRound =
      openTimingFirstround && openTimingFirstround.format("hh:mm A");

    // first round close --------firstRoundclosingTime------------------------------

    let closeTimingFirstround = moment()
      .hours(parseInt(firstRoundclosingTime && firstRoundclosingTime / 3600))
      .minutes(
        parseInt((firstRoundclosingTime && firstRoundclosingTime % 3600) / 60)
      )
      .seconds(parseInt(firstRoundclosingTime && firstRoundclosingTime % 60))
      .milliseconds(0);
    const CloseBetTimeFirstRound =
      closeTimingFirstround && closeTimingFirstround.format("hh:mm A");
    //
    const isBeforeFirstRound = currentTimeApis.isBefore(
      moment(CloseBetTimeFirstRound, "hh:mm A").utcOffset("+05:30")
    );

    // secound round related ---------------------------

    let openTimingSecondround = moment()
      .hours(parseInt(secoundRoundopenTime && secoundRoundopenTime / 3600))
      .minutes(
        parseInt((secoundRoundopenTime && secoundRoundopenTime % 3600) / 60)
      )
      .seconds(parseInt(secoundRoundopenTime && secoundRoundopenTime % 60))
      .milliseconds(0);
    const OpenBetTimeSecondRound =
      openTimingSecondround && openTimingSecondround.format("hh:mm A");
    // console.log(OpenBetTimeSecondRound)
    //second round ----close ----------
    let closeTimingSecondRound = moment()
      .hours(
        parseInt(secoundRoundclosingTime && secoundRoundclosingTime / 3600)
      )
      .minutes(
        parseInt(
          (secoundRoundclosingTime && secoundRoundclosingTime % 3600) / 60
        )
      )
      .seconds(
        parseInt(secoundRoundclosingTime && secoundRoundclosingTime % 60)
      )
      .milliseconds(0);
    const CloseBetTimeSecondRound =
      closeTimingSecondRound && closeTimingSecondRound.format("hh:mm A");
    const isBeforeSecondRound = currentTimeApis.isBefore(
      moment(CloseBetTimeSecondRound, "hh:mm A").utcOffset("+05:30")
    );

    console.log(CloseBetTimeSecondRound);
    console.log(isBeforeFirstRound);
    // console.log(moment(CloseBetTimeSecondRound, "hh:mm A").utcOffset("+05:30"))

    return (
      <>
        <div className="w-full " style={{ backgroundColor: "#fff" }}>
          <Loader loading={loading} />
          <div className=" flex space-x-1">
            <div className="lg:flex w-full  space-x-1">
              <div className="w-full space-y-1 h-[98vh] overflow-y-auto md:pb-20">
                <div className=" overflow-hidden space-y-4 py-2 w-full">
                  <div>
                    <marquee class="text-white bg-[#17273b] text-sm py-1 bgheader">
                      !! Welcome To Shillong Pocket !!
                    </marquee>
                    <div className="px-3 flex items-center font-bold ">
                      <IoChevronBackCircleSharp
                        onClick={() => this.props.history.goBack()}
                        className=" cursor-pointer text-4xl text-[#17273b]"
                      />
                      <span
                        className=" cursor-pointer"
                        onClick={() => this.props.history.goBack()}
                      >
                        Back
                      </span>
                    </div>
                  </div>
                  <div className="  px-2">
                    {gameList && gameList.length > 0
                      ? gameList.map((element, index) => {
                        // console.log("element",element)
                          if (
                            element.shortName !== "firstround" &&
                            element.shortName !== "second round"
                          ) {
                            let closaetimeofStar = moment()
                              .hours(
                                parseInt(element && element?.closingTime / 3600)
                              )
                              .minutes(
                                parseInt(
                                  (element && element?.closingTime % 3600) / 60
                                )
                              )
                              .seconds(
                                parseInt(element && element?.closingTime % 60)
                              )
                              .milliseconds(0);

                            

                            const isBeforeStarTime = currentTimeApis.isBefore(
                              moment(closaetimeofStar, "hh:mm A").utcOffset(
                                "+05:30"
                              )
                            );

                            const CloseTime =
                              closaetimeofStar &&
                              closaetimeofStar.format("hh:mm A");

                            // console.log(element)

                            return (
                              <div className="w-full border p-2 rounded-2xl border-[#fff]">
                                {element.shortName !== "firstround" &&
                                  element.shortName !== "second round" && (
                                    <div>

{(!isBeforeStarTime || element?.market?.closeNumber?.toString())&&<div className="bg-[#17273B] w-full text-[10px] md:text-base overflow-hidden  h-10 rounded-t-md shadow-lg text-white p-1 flex justify-center items-center uppercase  relative">
  <div className=" whitespace-nowrap font-bold text-yellow-400 animate-marquee">
    Total 🏆 winning amount💸 for this game : <span className=" text-[white]  "> 💰 ₹{element?.dummyAmount?(Number(element?.dummyAmount)+Number(element?.totalAmount)):element?.totalAmount}</span>.🏆🎉
  </div>
</div>}

                                    <div className="bg-[#17273b] rounded-lg rounded-t-none shadow-lg flex justify-between items-center p-1 ">
                                      <div className="cursor-pointer  flex justify-center items-center flex-col">
                                        {/* <img
                                          src={schedule}
                                          className=" w-12 h-12"
                                        /> */}
                                        
                                        {isBeforeStarTime &&
                                          !element?.market?.closeNumber?.toString()?<div className=" bg-green-600 flex p-2 md:w-20 justify-center items-center md:h-20 w-16 h-16 rounded-full">

                                        <FcAlarmClock  className=" md:w-16 md:h-14 w-12 h-10"/>
                                        </div>:
                                        
                                        <div className=" bg-gray-100  text-gray-800 flex p-2 md:w-20 justify-center items-center md:h-20 w-16 h-16 rounded-full">

                                        <FcAlarmClock  className=" md:w-16 md:h-14 w-12 h-10"/>
                                        </div>
                                        
                                        }
                                      </div>
                                    
                                      <div className=" flex flex-col justify-between items-center">
                                        <span className="uppercase text-base  text-[#ffff] lg:text-xl">
                                          {(CloseTime && CloseTime) || ""}
                                        </span>
                                        <h2 className="text-lg font-semibold text-white">
                                          {(element &&
                                            element?.market &&
                                            element?.market?.openNumber?.toString()) ||
                                            "X"}
                                          -
                                          {(element &&
                                            element?.market &&
                                            element?.market?.jodiNumber &&
                                            element?.market?.jodiNumber
                                              ?.toString()
                                              .split("")[0]) ||
                                            "X"}
                                          {(element &&
                                            element?.market &&
                                            element?.market?.jodiNumber &&
                                            element?.market?.jodiNumber.split(
                                              ""
                                            )[1]) ||
                                            "X"}
                                          -
                                          {(element &&
                                            element?.market &&
                                            element?.market?.closeNumber?.toString()) ||
                                            "X"}
                                        </h2>
                                        <span>
                                          {isBeforeStarTime &&
                                          !element?.market?.closeNumber?.toString() ? (
                                            <h2 className="text-base font-semibold bg-[#198f51] text-white rounded-lg px-4 py-2">
                                              Market Open
                                            </h2>
                                          ) : (
                                            <h2 className="text-base font-semibold bg-[#17273b] text-white rounded-lg px-4 py-2">
                                              Market Closed
                                            </h2>
                                          )}
                                        </span>
                                      </div>
                                     
                                      <div className=" flex flex-col justify-center items-center">
                                        <div
                                          className={`flex justify-center rounded-full  text-white ${
                                            isBeforeStarTime &&
                                            !element?.market?.closeNumber?.toString()
                                              ? "bg-[#198f51]"
                                              : "bg-[#17273b]"
                                          } w-12 h-12  items-center`}
                                        >
                                          {isBeforeStarTime &&
                                          !element?.market?.closeNumber?.toString() ? (
                                            <FaPlay
                                              className=" text-xl cursor-pointer"
                                              onClick={() =>
                                                this.navigateGameDetailsPage(
                                                  element
                                                )
                                              }
                                            />
                                          ) : (
                                            <FaPause className=" text-xl " />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    </div>
                                    
                                  )}
                              </div>
                            );
                          }
                        })
                      : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.isPastClosingTime ? (
            <BetCloseAlert handleCloseAlert={this.handleCloseAlert} />
          ) : null}

          {/* {notification &&
            <RulesNotification
              handleCloseNotification={this.handleCloseNotification}
              DomainName={DomainName}
            />} */}
        </div>
        {/* <Footer props={this.props} /> */}
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users,
  };
}

export default connect(mapStateToProps)(Dashboard2);
