import React from 'react';
import { connect } from 'react-redux';
import { alertActions, userActions } from '../../_actions';
import moment from 'moment'
import { IoCaretBackCircle } from "react-icons/io5";
import Loader from '../../components/Loader/Loader';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Models from './Models';
import Marquee from 'react-fast-marquee';
class MatkaSingle extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      gameItem: {},
      activeTab: 1,
      editStake: false,
      fieldStake: {},
      errorStake: {},
      fieldMarket: {},
      errorMarket: {},
      allBets: [],
      allBets2: [],
      allBets3: [],
      submitted: [],
      allPair: {},
      openmodel: false,
      visibleArry: [],
      submittedModel: [],
      NotnumList: [],
      initialValue: "",
      data: {
        "limit": 10,
        "pageno": 1
      },
      errorBets: [],

    }
    this.tableRef = React.createRef(null)
  }

  componentDidMount() {
    this.props.dispatch(userActions.getGamesByGamesIdForUser({ "gameId": this.props.match.params.gameId }))
    this.props.dispatch(userActions.getMarketListByGameId({ "gameId": this.props.match.params.gameId }));
    this.props.dispatch(userActions.getProfile());
    this.props.dispatch(userActions.getNumberWiseMarketSumForUser({
      "gameId": this.props.match.params.gameId,
    }));
    this.updateStateFromProps(this.props);
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    console.log( nextProps.users)
    const { gameData,getProfile} = nextProps.users;
    const {parentId} = getProfile?getProfile:{}
    const { NotnumList, NotnumList2 } = parentId || {};
    const { list } = gameData || {};
    const { shortName } = list || {};
    
    if (shortName === 'firstround') {
      return { NotnumList };
    } else if (shortName === 'second round') {
      return { NotnumList: NotnumList2 };
    }
    
    return null;
  }
  


  componentDidUpdate(prevProps) {
    // Check if relevant props have changed
    if (
      prevProps.users.gameData?.list?.shortName !== this.props.users.gameData?.list?.shortName ||
      prevProps.users.parentInfo !== this.props.users.parentInfo
    ) {
      this.updateStateFromProps(this.props); // Update state based on new props
    }
  }

  updateStateFromProps(props) {
    const { users } = props;
    const { parentInfo, gameData } = users;
    const { NotnumList, NotnumList2 } = parentInfo || {};
    const { list } = gameData || {};
    const { shortName } = list || {};

    if (shortName === 'firstround') {
      this.setState({ NotnumList });
    } else if (shortName === 'second round') {
      this.setState({ NotnumList: NotnumList2 });
    }
  }

  handleStakeChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;

    let { fieldStake, errorStake } = this.state;
    fieldStake[name] = parseFloat(value) >= 0 ? value : 0;
    errorStake[name] = "data not found";
    console.log("value  ", value);

    let sum = 0;

    for (const key in fieldStake) {
      if (fieldStake.hasOwnProperty(key)) {
        sum += parseInt(fieldStake[key]);
      }
    }

    this.setState({ fieldStake, errorStake, totalPoint: sum });
  };

  handleChangeSelect = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let { fieldMarket, errorStake } = this.state;
    fieldMarket[name] = value;
    errorStake[name] = "data not found";
    this.setState({ fieldMarket, errorStake });
    this.setState({
      errorStake: {
        ...this.state.errorStake,
        market: "",
        betNumber: '',
      }
    });
  };
  handleValid = () => {
    let { users } = this.props;
    let { marketList
    } = users;
    let jodiData = marketList && marketList.filter((e) => e.shortName == "JODI");
    let houseData = marketList && marketList.filter((e) => e.shortName == "house");
    let endingData = marketList && marketList.filter((e) => e.shortName == "ending");

    let minjodiAmount = jodiData && jodiData.length > 0 && jodiData[0] && jodiData[0]?.minBetAmount
    let manjodiAmount = jodiData && jodiData.length > 0 && jodiData[0] && jodiData[0]?.maxBetAmount

    let minhouseAmount = houseData && houseData.length > 0 && houseData[0] && houseData[0]?.minBetAmount
    let maxhouseAmount = houseData && houseData.length > 0 && houseData[0] && houseData[0]?.maxBetAmount

    let minendingAmount = endingData && endingData.length > 0 && endingData[0] && endingData[0]?.minBetAmount
    let manendingAmount = endingData && endingData.length > 0 && endingData[0] && endingData[0]?.maxBetAmount
    let errordata = this.state.submitted && this.state.submitted.filter((e, i) => {


      if (e.isOpen === true && (parseInt(e?.amount) < minhouseAmount || parseInt(e?.amount) > maxhouseAmount)) {
        return true;
      }
      if (e.isOpen === false && (parseInt(e?.amount) < minendingAmount || parseInt(e?.amount) > manendingAmount)) {
        return true;
      }
      if (e.isOpen === null && (parseInt(e?.amount) < minjodiAmount || parseInt(e?.amount) > manjodiAmount)) {
        return true;
      }
      return false;
    });

    this.setState({ errorBets: [...errordata] });


    if (errordata && errordata.length == 0) {

      return true
    } else {
      alert("Invalid bets")
      return false
    }

  }
  placeBet2 = () => {
    let { users } = this.props;
    let { gameData, getProfile } = users;
    let { list } = gameData ? gameData : {};
    let { NotnumList } = list ? list : {};






    if (this.handleValid()) {
      const validBets = this.state.submitted.filter(bet => {
        console.log(bet)
        const amount = parseFloat(bet.amount);
        return !isNaN(amount) && amount !== 0 && !NotnumList.includes(bet.betNumber);
      });

      if (validBets && validBets.length == 0) {
        alert("Bet is not selected. Please select")
        return;
      }

      let reqData = {
        "gameId": this.props.match.params.gameId,
        "bets": validBets,
        "isOpen": false,
        "parentId": getProfile?.parentId || null
      };

      this.props.dispatch(userActions.placeBet(reqData, this.props));
    }
  }



  handleChange = (e) => {
    let { name, value } = e.target;
    console.log(name, value);
    this.setState((prevState) => ({
      allBets: {
        ...prevState.allBets,
        [name]: value,
      },
    }));
  }
  handleChange2 = (e) => {
    let { name, value } = e.target;
    console.log(name, value);
    this.setState((prevState) => ({
      allBets2: {
        ...prevState.allBets2,
        [name]: value,
      },
    }));
  }
  handleChange3 = (e) => {
    let { name, value } = e.target;
    console.log(name, value);
    this.setState((prevState) => ({
      allBets3: {
        ...prevState.allBets3,
        [name]: value,
      },
    }));
  }



  handleAmount = (e) => {
    console.log(e.target.value)
    this.setState({ initialValue: e.target.value });
  }
  handleClick = (e) => {
    const { name, value } = e.target;
    console.log(e.target.name)
    this.setState((prevState) => ({
      allBets2: {
        ...prevState.allBets2,
        [name]: this.state.initialValue,
      },
    }));
  }
  handleClick2 = (e) => {
    const { name, value } = e.target;
    console.log(e.target.name)
    this.setState((prevState) => ({
      allBets: {
        ...prevState.allBets,
        [name]: this.state.initialValue,
      },
    }));
  }
  handleClick3 = (e) => {
    const { name, value } = e.target;
    console.log(e.target.name)
    this.setState((prevState) => ({
      allBets3: {
        ...prevState.allBets3,
        [name]: this.state.initialValue,
      },
    }));
  }



  handleChangeNew = (updatedData, updatedNumber, updatedIsOpen, updatedAmount) => {
    console.log(updatedData, updatedNumber, updatedIsOpen, updatedAmount)
    const { _id } = updatedData;
    const updatedSubmitted = [...this.state.submitted];
    const existingIndex = updatedSubmitted.findIndex(item => item.marketId === _id && item.isOpen === updatedIsOpen && item.betNumber === updatedNumber);

    if (existingIndex !== -1) {
      // Update existing object
      updatedSubmitted[existingIndex] = {
        ...updatedSubmitted[existingIndex],
        betNumber: updatedNumber,
        isOpen: updatedIsOpen,
        amount: updatedAmount.target.value
      };
    } else {
      // Add new object
      updatedSubmitted.push({
        marketId: _id,
        betNumber: updatedNumber,
        isOpen: updatedIsOpen,
        amount: updatedAmount.target.value
      });
    }

    this.setState({ submitted: updatedSubmitted });
  };

  handleChangeinmodel = (e, value) => {
    console.log(e)
    console.log(value)
    // submittedModel
    if (value.length < 8) {


      const updatedSubmitted = [...this.state.submittedModel];
      const existingIndex = updatedSubmitted.findIndex(item => item.marketId === e?._id && item.isOpen === e?.flag && item.betNumber === e?.num);
      if (existingIndex !== -1) {
        // Update existing object
        updatedSubmitted[existingIndex] = {
          ...updatedSubmitted[existingIndex],
          betNumber: e?.num,
          isOpen: e?.flag,
          amount: value
        };
      } else {
        updatedSubmitted.push({
          marketId: e?._id,
          betNumber: e?.num,
          isOpen: e?.flag,
          amount: value
        });
      }
      this.setState({ submittedModel: updatedSubmitted });
    }
  }

  handleClickfinal = (updatedData, updatedNumber, updatedIsOpen, updatedAmount) => {
    const { _id } = updatedData;
    const updatedSubmitted = [...this.state.submitted];
    const existingIndex = updatedSubmitted.findIndex(item => item.marketId === _id && item.isOpen === updatedIsOpen && item.betNumber === updatedNumber);

    if (existingIndex !== -1) {
      // Update existing object
      updatedSubmitted[existingIndex] = {
        ...updatedSubmitted[existingIndex],
        betNumber: updatedNumber,
        isOpen: updatedIsOpen,
        amount: this.state.initialValue
      };
    } else {
      // Add new object
      updatedSubmitted.push({
        marketId: _id,
        betNumber: updatedNumber,
        isOpen: updatedIsOpen,
        amount: this.state.initialValue
      });
    }

    this.setState({ submitted: updatedSubmitted });

  };


  handlePair = async () => {
    let { users } = this.props;
    let { marketDetails, marketLoading, loading, gameData, getMarketList, numberWiseSum, getProfile } = users;
    let { marketList } = getMarketList ? getMarketList : {};
    let { fieldStake, fieldMarket, errorStake, totalPoint } = this.state;
    let { list } = gameData ? gameData : {};
    let { NotnumList } = list ? list : {};

    const marketId = marketList?.find(e => e.shortName === "Pair")?._id;
    console.log("------<<---", marketId);

    let numberdata = "0000";

    // Get the current visibleArry from the state
    const { visibleArry } = this.state;

    let obj = {
      _id: marketId,
      flag: null,
      num: numberdata
    };

    // Check if the object is already in the visibleArry
    const isAlreadyPresent = visibleArry.some(item => item._id === obj._id);

    if (!isAlreadyPresent) {
      // Update the state with the new filtered array if not already present
      await this.setState(prevState => ({
        visibleArry: [...prevState.visibleArry, obj]
      }));
    }
  };

















  handleAllPairChange = (e) => {
    const { users } = this.props;
    const { getMarketList } = users;
    let { marketList
    } = getMarketList ? getMarketList : {};
    const marketId = marketList?.find(e => e.shortName === "JODI")?._id;
    console.log(marketId)
    const { submitted } = this.state;
    const updatedSubmitted = [...submitted];
    this.setState(prev => ({
      ...prev, // spread the previous state
      allPair: e.target.value // update the allPair property with the new value
    }));


    let arraydata = ["00", 11, 22, 33, 44, 55, 66, 77, 88, 99]
    for (let i = 0; i < arraydata.length; i++) {
      const existingIndex = updatedSubmitted.findIndex(item => item.isOpen === null && item.betNumber === arraydata[i]);

      if (existingIndex !== -1) {
        // Update existing object
        updatedSubmitted[existingIndex] = {
          ...updatedSubmitted[existingIndex],
          betNumber: arraydata[i],
          isOpen: null,
          amount: e.target.value
        };
      } else {
        updatedSubmitted.push({
          marketId: marketId,
          betNumber: arraydata[i],
          isOpen: null,
          amount: e.target.value
        });
      }


    }


    this.setState({ submitted: updatedSubmitted });

  }


  generatePDF = () => {
    const table = this.tableRef.current;
    html2canvas(table).then((canvas) => {
      const pdf = new jsPDF('p', 'mm', 'a4');
      const width = pdf.internal.pageSize.getWidth();
      const height = pdf.internal.pageSize.getHeight();
      const imgWidth = width - 20; // Adjust the margin as needed
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(canvas.toDataURL('image/PNG'), 'PNG', 10, 10, imgWidth, imgHeight);
      pdf.save('table.pdf');
    });
  };




  handleAddinModel = (e, num, flag) => {
    console.log(e, num, flag,);
    const newObj = { _id: e?._id, num, flag };
    if (this.state.visibleArry.some((elem) => elem._id == e?._id && elem.num == num && elem.flag == flag)) {

    } else {

      this.setState((prevState) => ({
        visibleArry: [...prevState.visibleArry, newObj]
      }));
    }
  }


  handleClick5 = () => {
    console.log(this.state.openmodel)
    this.setState({ openmodel: true })
  }

  handleButton = async () => {
    console.log(this.state.submittedModel)
    let { users } = this.props;
    let { gameData, getProfile } = users;
    let { list } = gameData ? gameData : {};
    let { NotnumList } = list ? list : {};


    if (this.state.submittedModel && this.state.submittedModel.length == 0) {
      this.props.dispatch(alertActions.error("Invalid"))
      return;
    }



// Check if any bet has 0 or negative amount
if (this.state.submittedModel.some(bet => Number(bet.amount) <= 0)) {
  this.props.dispatch(alertActions.error("Invalid: Some bets have 0 or negative amount."));
  return;
}


    let newarray = [];

    if (this.state.submittedModel && this.state.visibleArry && this.state.visibleArry.length > 0) {
      newarray = this.state.submittedModel.filter((element) => {
        let isMatch = false;
        this.state.visibleArry.forEach((e) => {
          if (e._id == element.marketId && e.num.toString() === element.betNumber.toString() && e.flag == element.isOpen) {

            isMatch = true;
          }
        });
        return isMatch;
      });
    }




    let req = {

      "gameId": this.props.match.params.gameId,
      "bets": newarray || [],
      "isOpen": false,
      "parentId": getProfile?.parentId || null
    }



    await this.props.dispatch(userActions.placeBet(req, this.props.match.params.gameId));
    this.setState({ submittedModel: [] });
    this.setState({ visibleArry: [] });
    this.handleCloseModel();


  }


  handleReset = () => {
    this.setState({ submittedModel: [] });
    this.setState({ visibleArry: [] });
    this.handleCloseModel();
  }


  handleCloseModel = () => {
    this.setState({ openmodel: false })
  }


  handleAlert = () => {
    alert("Select number First")
    return;
  }



  handleremoveNumber = (e) => {

    const indexofelement = this.state.visibleArry.findIndex(elem =>
      elem._id === e?._id && elem.num === e?.num && elem.flag === e?.flag
    );
    if (this.state.visibleArry && this.state.visibleArry.length !== 1) {


      this.state.visibleArry.splice(indexofelement, 1);

      this.setState({ visibleArry: this.state.visibleArry })
    }

  }

  render() {
    console.log(this.state.NotnumList)
    let { users } = this.props;
    let { parentInfo, loading, gameData, getMarketList, numberWiseSum, getProfile
    } = users;

    let { marketList
    } = getMarketList ? getMarketList : {};
    let { fieldStake, fieldMarket, errorStake, totalPoint } = this.state;

    let { currentTime, list } = gameData ? gameData : {};
    let { openTime, closingTime, betOpenTime, betClosingTime, max, min, chartList, name } = list ? list : {};
    let { NotnumList, NotnumList2 } = parentInfo ? parentInfo : {};

    console.log("--------->>", list)
    const openingTime = moment()
      .hours(parseInt(openTime && openTime / 3600))
      .minutes(parseInt((openTime && openTime % 3600) / 60))
      .seconds(parseInt(openTime && openTime % 60))
      .milliseconds(0);

    const closingTimes = moment()
      .hours(parseInt(closingTime && closingTime / 3600))
      .minutes(parseInt((closingTime && closingTime % 3600) / 60))
      .seconds(parseInt(closingTime && closingTime % 60))
      .milliseconds(0);

    const closeCloseBidTime = moment.duration(betClosingTime && betClosingTime, 'seconds').asMinutes()
    const closeOpenBidTime = moment.duration(betOpenTime && betOpenTime, 'seconds').asMinutes()

    const MinutesBeforeClosing = closingTimes.clone().subtract(closeCloseBidTime, 'minutes');
    const MinutesBeforeOpening = openingTime.clone().subtract(closeOpenBidTime, 'minutes');

    //formate
    const CloseBetMinutesBeforeClosing = MinutesBeforeClosing.format("hh:mm A");
    const CloseBetMinutesBeforeOpening = MinutesBeforeOpening.format("hh:mm A");

    //current time
    const currentTimeApis = moment.unix(currentTime).format("hh:mm A");

    const isBeforeClose = moment(currentTimeApis, "hh:mm A").isBefore(moment(CloseBetMinutesBeforeClosing, "hh:mm A"));
    const isBeforeOpen = moment(currentTimeApis, "hh:mm A").isBefore(moment(CloseBetMinutesBeforeOpening, "hh:mm A"));

    let jodiData = marketList && marketList.filter((e) => e.shortName == "JODI");
    let houseData = marketList && marketList.filter((e) => e.shortName == "house");
    let endingData = marketList && marketList.filter((e) => e.shortName == "ending");

    let minjodiAmount = jodiData && jodiData.length > 0 && jodiData[0] && jodiData[0]?.minBetAmount
    let manjodiAmount = jodiData && jodiData.length > 0 && jodiData[0] && jodiData[0]?.maxBetAmount

    let minhouseAmount = houseData && houseData.length > 0 && houseData[0] && houseData[0]?.minBetAmount
    let maxhouseAmount = houseData && houseData.length > 0 && houseData[0] && houseData[0]?.maxBetAmount

    let minendingAmount = endingData && endingData.length > 0 && endingData[0] && endingData[0]?.minBetAmount
    let manendingAmount = endingData && endingData.length > 0 && endingData[0] && endingData[0]?.maxBetAmount

    // console.log("--------------<>",this.state.NotnumList)

    return (
      <div className='w-full h-full relative overflow-y-auto bg-gray-200'>
        <Loader loading={loading} />

        <div className="w-full lg:p-2 p-1 rounded-lg mb-24 space-y-10 ">


          <div className='bg-white w-full relative  space-y-5  flex  flex-col'>
            <div className=' lg:text-5xl text-3xl lg:top-4 top-5 left-1 absolute text-[#17273b]'>

              <IoCaretBackCircle className=' cursor-pointer' onClick={() => this.props.history.goBack()} />
            </div>
            <div className='flex justify-center items-center '>
              <span className='lg:text-4xl capitalize text-2xl underline  lg:font-[800] font-[800] lg:text-[#495057] text-[#495057]'>{name && name}</span>
            </div>
            <div class="bg-[#17273B] text-white px-6 py-4 rounded-lg md:text-xl text-sm font-semibold text-center shadow-lg ">
            🏆 Join and Play ! 🏹 Teer 🏹 by POCKET-X 🏆
  </div>

            <marquee class="text-white bg-[#17273b]  font-bold mt-1 text-sm py-1 bgheader">Jodi Amount Should Be Between  {minjodiAmount && minjodiAmount || ''} - {getProfile?.parentId && getProfile?.parentId?.jodiLimit && Number(getProfile?.parentId?.jodiLimit) / 100 || ""}  **   House Amount Should Be Between {minhouseAmount && minhouseAmount || ''} - {getProfile?.parentId && getProfile?.parentId?.houseLimit && Number(getProfile?.parentId?.houseLimit) / 10 || ""}   **   Ending Amount Should Be Between  {minendingAmount && minendingAmount || ''} - {getProfile?.parentId && getProfile?.parentId?.endingLimit && Number(getProfile?.parentId?.endingLimit) / 10 || ""} </marquee>




            <div className=' overflow-hidden overflow-x-auto w-[100%] '>
              {marketList && marketList.length > 0 && (
                <table className='' style={{ borderCollapse: 'separate', borderSpacing: '1px', width: '100%' }}>
                  <tbody >
                    {marketList
                      .filter(e => e.shortName === "JODI")
                      .map((e, index) => {
                        const numbers = e.numList.slice(0, 100); // Limiting to 100 numbers
                        const rows = [];
                        for (let i = 0; i < 10; i++) {
                          const cols = [];
                          for (let j = 0; j < 10; j++) {
                            const numIndex = i * 10 + j;


                            cols.push(<>

                              {!this.state.NotnumList?.includes(numbers[numIndex].toString()) ?
                                <td onClick={(data) => this.handleAddinModel(e, numbers[numIndex], null, data)} key={j}



                                  className={
                                    this.state.visibleArry.some((elem) => elem._id == e?._id && elem.num == numbers[numIndex] && elem.flag == null) ?
                                      " bg-green-600 text-xs md:text-base text-black rounded-lg"
                                      :
                                      " rounded-lg text-xs md:text-base bg-[#019CF4] text-black"
                                  }


                                  style={{ border: '1px solid black', padding: '2px', textAlign: 'center' }}>{numbers[numIndex]}
                                  <div className='relative'>



                                    {

                                      this.state.errorBets &&
                                      this.state.errorBets.length > 0 &&
                                      this.state.errorBets.some((e) => e.betNumber.toString() == numbers[numIndex]?.toString()) && <div className=' text-red-500 text-xs'>Invalid</div>
                                    }


                                  </div>
                                </td>
                                :
                                <td key={j}



                                  className={
                                    this.state.visibleArry.some((elem) => elem._id == e?._id && elem.num == numbers[numIndex] && elem.flag == null) ?
                                      " bg-green-600 text-xs md:text-base  text-black rounded-lg"
                                      :
                                      " bg-red-800 text-xs md:text-base text-black rounded-lg"
                                  }
                                  title='Blocked'

                                  style={{ border: '1px solid black', padding: '2px', textAlign: 'center' }}>{numbers[numIndex]}
                                  <div className='relative'>




                                    {

                                      this.state.errorBets &&
                                      this.state.errorBets.length > 0 &&
                                      this.state.errorBets.some((e) => e.betNumber.toString() == numbers[numIndex]?.toString()) && <div className=' text-red-500 text-xs'>Invalid</div>
                                    }



                                  </div>
                                </td>

                              }
                            </>
                            );
                          }

                          cols.push(





                            <>

                              {marketList.filter(e => e.shortName === "house").map((e, index) => {


                                if (!this.state.NotnumList?.includes(`${e.numList[i].toString()} HOUSE`)) {
                                  return (

                                    <td
                                      className={
                                        this.state.visibleArry.some((elem) => elem._id == e?._id && elem.num == e.numList[i] && elem.flag == true) ?
                                          " bg-green-600  text-xs md:text-base rounded-lg text-center text-black"
                                          :
                                          " bg-[#23B8DD] text-center text-xs md:text-base  rounded-lg"
                                      }


                                    >
                                      <div onClick={(data) => this.handleAddinModel(e, e.numList[i], true, data)}

                                        className={
                                          this.state.visibleArry.some((elem) => elem._id == e?._id && elem.num == e.numList[i] && elem.flag == true) ?
                                            " bg-green-600 text-xs md:text-base rounded-lg text-black "
                                            :
                                            " bg-[#23B8DD]  text-xs md:text-base rounded-lg"
                                        }


                                        key={index}>{e.numList[i]} HOUSE
                                      </div>
                                    </td>
                                  )
                                } else {
                                  return (
                                    <td className={
                                      this.state.visibleArry.some((elem) => elem._id == e?._id && elem.num == e.numList[i] && elem.flag == true) ?
                                        " bg-green-600 text-xs md:text-base text-black"
                                        :
                                        " bg-red-800 text-center text-xs md:text-base  rounded-lg"
                                    }



                                    >



                                      <div
                                        title=' Blocked'
                                        className={
                                          this.state.visibleArry.some((elem) => elem._id == e?._id && elem.num == e.numList[i] && elem.flag == true) ?
                                            " bg-green-600 text-xs md:text-base text-black"
                                            :
                                            " bg-red-800 text-center text-xs md:text-base text-black"
                                        }


                                        key={index}>{e.numList[i]} HOUSE
                                      </div>
                                    </td>)
                                }



                              })}
                            </>
                          );
                          rows.push(<tr key={i}>{cols}</tr>);
                        }
                        return rows;
                      })}
                    <tr className='bg-[#a5cdff] '>
                      {marketList && marketList.length > 0 && marketList
                        .filter(e => e.shortName === "ending")
                        .map((e, index) => (
                          e.numList.map((num, idx) => {
                            if (!this.state.NotnumList?.includes(`${num.toString()} ENDING`)) {
                              return (
                                <td key={idx} onClick={(data) => this.handleAddinModel(e, num, false, data)}

                                  className={
                                    this.state.visibleArry.some((elem) => elem._id == e?._id && elem.num == num && elem.flag == false) ?
                                      " bg-green-600 text-xs md:text-base text-black rounded-lg"
                                      :
                                      " bg-[#73B2FF] text-xs md:text-base  rounded-lg"
                                  }
                                  style={{ border: '1px solid black', padding: '2px' }}>
                                  {num} END
                                  {!this.state.NotnumList?.includes(`${num.toString()} ENDING`) && <></>


                                  }

                                </td>
                              )

                            } else {
                              return (
                                <td key={idx}
                                  title='Blocked'
                                  className={
                                    this.state.visibleArry.some((elem) => elem._id == e?._id && elem.num == num && elem.flag == false) ?
                                      " bg-green-600 text-xs md:text-base text-black rounded-lg"
                                      :
                                      " bg-red-800 text-xs md:text-base text-black rounded-lg"
                                  }
                                  style={{ border: '1px solid black', padding: '2px' }}>
                                  {num} END
                                  {!this.state.NotnumList?.includes(`${num.toString()} ENDING`) && <></>


                                  }
                                  {/* {NotnumList?.includes(`${num.toString()} ENDING`) && <MdOutlineBlock className=" top-1/2 right-1/2 absolute text-red-500" />} */}
                                </td>
                              )
                            }


                          })
                        ))}
                      <td onClick={() => this.handlePair()}
                        className={
                          this.state.visibleArry.some((elem) => elem.num.toString() == "0000" && elem.flag == null) ?
                            " bg-green-600 text-xs md:text-base text-black text-center rounded-lg"
                            :
                            " bg-[#73B2FF] text-xs md:text-base   text-black text-center rounded-lg"
                        }


                      >
                        All Pairs


                      </td>
                    </tr>

                  </tbody>
                </table>
              )}
            </div>


            <div className='flex justify-center items-center p-4'>

              {this.state.visibleArry && this.state.visibleArry.length > 0 ?
                <div className=' flex gap-3'>

                  <button
                    onClick={this.handleClick5}
                    className="w-full md:w-auto py-1.5 px-4 bg-[#018D95] active:scale-90  transition-transform duration-200 text-white text-xl rounded"
                  >
                    Submit
                  </button>
                  <button
                    onClick={this.handleReset}
                    className="w-full md:w-auto py-1.5 px-4 bg-[#913030] lg:bg-[#913030] text-white text-xl rounded"
                  >
                    Reset
                  </button>
                </div>
                :
                <button
                  onClick={this.handleAlert}
                  title='Disabled'
                  className="w-full md:w-auto py-1.5 px-4 bg-[#018D95] active:scale-90  transition-transform duration-200 text-white text-xl rounded"
                >
                  Submit
                </button>
              }

            </div>





            <div className="overflow-x-auto w-full">
              <table className=" w-full divide-y-2 bg-white border border-gray-300">
                <thead>
                  <tr>
                    <th className="px-4 py-2 border-b border-gray-300 text-center">S.NO</th>
                    <th className="px-4 py-2 border-b border-gray-300 text-center">Bet Number</th>
                    <th className="px-4 py-2 border-b border-gray-300 text-center">Type</th>
                    <th className="px-4 py-2 border-b border-gray-300 text-center">Total Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {numberWiseSum && numberWiseSum.length > 0 ? (
                    numberWiseSum.map((item, index) => (
                      <tr key={index} className="hover:bg-gray-100">
                        <td className="px-4 py-2 border-b border-gray-300 text-center">{index + 1}</td>
                        {item?.betNumber.toString() == "0000" && <td className="px-4 py-2 border-b border-gray-300 text-center">All Pair</td>}

                        {item?.betNumber.toString() !== "0000" && <td className="px-4 py-2 border-b border-gray-300 text-center">{item.betNumber}</td>}

                        {item?.isOpen == null && item?.betNumber.toString() == "0000" && <td className="px-4 py-2 border-b border-gray-300 text-center"> All Pair </td>}

                        {item?.isOpen == null && item?.betNumber.toString() != "0000" && <td className="px-4 py-2 border-b border-gray-300 text-center">Jodi</td>}

                        {item?.isOpen == true && <td className="px-4 py-2 border-b border-gray-300 text-center">House</td>}

                        {item?.isOpen == false && <td className="px-4 py-2 border-b border-gray-300 text-center">Ending</td>}
                        <td className="px-4 py-2 border-b border-gray-300 text-center">{item.totalAmount}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3" className="px-4 py-2 border-b border-gray-300 text-center">No data available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>










          </div>
        </div>
        <Models
          openmodel={this.state.openmodel}
          visibleArry={this.state.visibleArry}
          handleChangeNew={this.handleChangeNew}
          handleChangeinmodel={this.handleChangeinmodel}
          submittedModel={this.state.submittedModel}
          handleButton={this.handleButton}
          handleCloseModel={this.handleCloseModel}
          handleremoveNumber={this.handleremoveNumber}
          name={name}
        />


      </div>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users
  };
}

export default connect(mapStateToProps)(MatkaSingle);


























